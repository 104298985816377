<template>
  <a-spin :spinning="spinning">
    <template #indicator>
      <Loading></Loading>
    </template>
    <div class="result">
      <div class="content">
        <div v-if="page === 'CheckoutFailed'">
          <img class="status" src="../../../assets/images/checkout/failed.png" />
          <div class="title">{{ $t('message.checkout.paymentFailed') }}</div>
          <div class="reason" v-if="result">{{ result.message }}</div>
          <a-button v-if="result && result.redirectUrl" @click="shopping" class="continue" type="link">{{
            $t('message.checkout.continueShopping')
          }}</a-button>
        </div>
        <div class="success" v-else-if="page === 'CheckoutSuccess'">
          <img class="status" src="../../../assets/images/checkout/success.png" />
          <div class="title">{{ $t('message.checkout.paymentSuccess') }}</div>
          <div class="actions" v-if="result && result.redirectUrl">
            <span class="countdown">{{ countdown }} s</span>
            <a-button @click="shopping" class="continue" type="link">{{ $t('message.checkout.continueShopping') }}</a-button>
          </div>
        </div>
        <div class="pending" v-else>
          <img class="status" src="../../../assets/images/checkout/pending.png" />
          <div class="title">{{ $t('message.checkout.paymentPending') }}</div>
          <div class="actions" v-if="result && result.redirectUrl">
            <span class="countdown">{{ countdown }} s</span>
            <a-button @click="shopping" class="continue" type="link">{{ $t('message.checkout.continueShopping') }}</a-button>
          </div>
        </div>
      </div>
      <div class="btns">
        <a-button v-if="result && result.redirectUrl" @click="shopping" type="primary" size="large">{{
          $t('message.checkout.resultToMerchant')
        }}</a-button>
      </div>
    </div>
  </a-spin>
</template>
<script>
import Loading from '@/components/loading'
import { getOrderStatus } from '@/views/checkout/api/index'
import { isEmptyValue } from '@/utils/tools/utils'

export default {
  components: { Loading },
  data() {
    return {
      id: this.$route.params.id,
      page: this.$route.name,
      result: null,
      spinning: true,
      countdown: 0
    }
  },
  async created() {
    try {
      this.result = await getOrderStatus(this.id)
      if (['CheckoutSuccess', 'CheckoutPending'].includes(this.page) && this.result.redirectUrl) {
        this.countdown = 6
      }
      this.spinning = false
    } catch (error) {
      this.spinning = false
    }
    if (this.countdown > 0 && !isEmptyValue(this.result.redirectUrl)) {
      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1
        } else {
          window.location.href = this.result.redirectUrl
          clearInterval(timer)
        }
      }, 1000)
    }
  },
  methods: {
    shopping() {
      window.location.href = this.result.redirectUrl
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';
.result {
  padding-top: 37px;
  .content {
    width: 886px;
    height: 666px;
    background: @white;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.11);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .title {
      #Font.heavy();
      font-size: 16px;
      line-height: 22px;
      color: @checkout-more-color;
    }
    .reason {
      #Font.regular();
      font-size: 14px;
      line-height: 19px;
      color: @checkout-error-color1;
      margin-top: 7px;
    }
    .continue {
      height: 22px;
      line-height: 22px;
      margin-top: 27px;
      padding: 0;
    }
    .success,
    .pending {
      .countdown {
        #Font.heavy();
        font-size: 16px;
        line-height: 22px;
        color: @checkout-more-color;
        margin-right: 10px;
      }
      .continue {
        margin-top: 13px;
      }
    }
  }
  .btns {
    display: none;
  }
}
@media screen and(max-width: 900px) {
  .result {
    padding: 37px 20px 0;
    .content {
      width: 100%;
      height: fit-content;
      display: block;
      padding-bottom: 35px;
    }
    .btns {
      margin-top: 20px;
      display: block;
    }
  }
}
</style>
